import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import {FixedBottom} from 'react-fixed-bottom';

import * as constants from './Constants.js';
import Cookies from 'js-cookie';
import Login from './Login.js';
import ManageFriends from './ManageFriends.js';
import CreateScene from './CreateScene.js';
import Notifications from './Notifications.js';
import ViewScene from './ViewScene.js';
import Feed from './Feed.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import TermsOfService from './TermsOfService.js';
import { isHttps } from './Util.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { messaging } from "./init-fcm";
import { Helmet } from 'react-helmet';

function sendTokenToServer(token) {
  fetch(constants.API_ADDRESS + '/firebase_token', {
    method: 'PUT',
    crossDomain: true,
    headers: {
      'UserId': Cookies.get(constants.COOKIE_USER_ID),
      'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
      'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
    },
    body: JSON.stringify({
      'device_id': Cookies.get(constants.COOKIE_DEVICE_ID),
      'token': token
    })
  }).then(response => response.json())
  .then(response => {
    console.log(response);
  });
}

class App extends React.Component {
  constructor(props){
    super(props);
    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.setHasNewNotifications = this.setHasNewNotifications.bind(this);
    // TODO: Switch to counting the number of notifications once API is finalized.
    this.state = {
      hasNewNotifications: false
    };
    this.fetchNotifications();
  }

  async componentDidMount() {
    if (messaging) {
      messaging.requestPermission().then(async function() {
        const token = await messaging.getToken();
        sendTokenToServer(token);
      }).catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });
      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          console.log('Token refreshed.');
          sendTokenToServer(refreshedToken);
        }).catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
        });
      });
      navigator.serviceWorker.addEventListener("message", (message) => {
        console.log(message);
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            var receivedNotification = message.data['firebase-messaging-msg-data'].notification;
            var notification = new Notification(receivedNotification.title, {
              body: receivedNotification.body,
              icon: receivedNotification.icon
            });
            notification.onclick = function(event) {
              window.location.href = receivedNotification.click_action;
            };
          }
        });
        this.setHasNewNotifications(true);
        console.log(message);
      });
    } else {
      this.fetchNotifications();
      setInterval(this.fetchNotifications, constants.REFRESH_INTERVAL);
    }
  }

  fetchNotifications() {
    fetch(constants.API_ADDRESS + '/notification', {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        // TODO: handle the last notification on the server side, so that users
        // into a new device can still see the new notifications.
        if (Cookies.get(constants.COOKIE_LAST_NOTIFICATION_TIMESTAMP) == null) {
          Cookies.set(constants.COOKIE_LAST_NOTIFICATION_TIMESTAMP, new Date().getTime(), { secure: isHttps(), expires: constants.COOKIE_FOREVER_DURATION })
        } else if (response.notifications.length > 0
              && response.notifications[0]._id.timestamp > Cookies.get(constants.COOKIE_LAST_NOTIFICATION_TIMESTAMP)) {
          this.setHasNewNotifications(true);
          Cookies.set(constants.COOKIE_LAST_NOTIFICATION_TIMESTAMP, response.notifications[0]._id.timestamp, { secure: isHttps(), expires: constants.COOKIE_FOREVER_DURATION });
        }
      }
    });
  }

  setHasNewNotifications(hasNewNotifications) {
    this.setState({hasNewNotifications: hasNewNotifications});
  }

  render() {
    var footerStyle = {
      backgroundColor: "#F8F8F8",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "20px",
      width: "100%",
    }

    return (
      <div>
      <Helmet>
        <title>{ constants.APP_TITLE } { this.state.hasNewNotifications ? ('(*)') : ('') }</title>
      </Helmet>
      <Router>
        <Container style={{height:'100%'}}>
          <div id="app" style={{height:'100%'}}>
            <Navbar style={{'border-bottom':'1px solid #D3D3D3'}} expand="sm">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/friends">Friends</Nav.Link>
                  <Nav.Link href="/scene/create">Create</Nav.Link>
                  <Nav.Link href="/notifications">Notifications {this.state.hasNewNotifications ? (<span class="badge badge-secondary">New</span>) : ('')}</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <br/>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/friends">
                <ManageFriends />
              </Route>
              <Route path="/notifications">
                <Notifications setHasNewNotificationsFunction={this.setHasNewNotifications}/>
              </Route>
              <Route path="/scene/create">
                <CreateScene />
              </Route>
              <Route path="/scene/view/:sceneId" component={ViewScene} />
              <Route path="/privacy_policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/terms_of_service">
                <TermsOfService />
              </Route>
              <Route path="/">
                <Feed />
              </Route>
            </Switch>
            <br/><br/><br/><br/><br/>
            </div>
          </Container>
      </Router>
      <FixedBottom offset={0}>
        <div style={footerStyle}>
          By using this website, you agree to our <a href='/privacy_policy'>Privacy Policy</a> and <a href='/terms_of_service'>Terms of Service</a>.
        </div>
      </FixedBottom>
      </div>
    );
  }
}

export default App;
