import * as constants from './Constants.js';

import Cookies from 'js-cookie';
import React from 'react';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import { isHttps } from './Util.js';

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showAlert: false, alertMessage: ''};
    this.generateDeviceIdIfNotExist = this.generateDeviceIdIfNotExist.bind(this);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
    this.onRegisterButtonClick = this.onRegisterButtonClick.bind(this);

    if (Cookies.get(constants.COOKIE_USER_ID) != null && Cookies.get(constants.COOKIE_AUTH_TOKEN) != null) {
      fetch(constants.API_ADDRESS + '/user', {
        method: 'GET',
        crossDomain: true,
        headers: {
          'UserId': Cookies.get(constants.COOKIE_USER_ID),
          'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
          'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
        }
      }).then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status == 'ok') {
            window.location.href = '/';
        }
      });
    }
  }

  generateDeviceIdIfNotExist() {
    var deviceId = Cookies.get(constants.COOKIE_DEVICE_ID);
    if (deviceId == null) {
      deviceId = uuidv4();
      Cookies.set(constants.COOKIE_DEVICE_ID, deviceId, {secure: isHttps(), expires: constants.COOKIE_FOREVER_DURATION});
    }
    return deviceId;
  }

  onLoginButtonClick() {
    var deviceId = this.generateDeviceIdIfNotExist();
    fetch(constants.API_ADDRESS + '/user', {
      method: 'PUT',
      crossDomain: true,
      body: JSON.stringify({username: this.username.value, password: this.password.value, device_id: deviceId})
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        Cookies.set(constants.COOKIE_AUTH_TOKEN, response.auth_token, {secure: isHttps(), expires: 365});
        Cookies.set(constants.COOKIE_USER_ID, response.user_id, {secure: isHttps(), expires: constants.COOKIE_FOREVER_DURATION});
        this.generateDeviceIdIfNotExist();
        window.location.href = '/';
      } else if (response.status == 'error' && response.message == 'invalid_username_password') {
        this.setState({showAlert: true, alertMessage: 'You\'re not hackerman scene.'});
      }
    }).catch(error => console.log(error));
  }

  onRegisterButtonClick() {
    var deviceId = this.generateDeviceIdIfNotExist();
    fetch(constants.API_ADDRESS + '/user', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({username: this.username.value, password: this.password.value, device_id: deviceId})
    }).then(response => response.json())
    .then(response => {
      if (response.status == 'error' && response.message == 'username_already_exists') {
        this.setState({showAlert: true, alertMessage: 'Username taken. Tough scene.'});
      } else if(response.status == 'error' && response.message == 'invalid_username_characters') {
        this.setState({showAlert: true, alertMessage: 'Invalid username characters. No special characters allowed scene.'});
      } else {
        Cookies.set(constants.COOKIE_AUTH_TOKEN, response.auth_token, {secure: isHttps(), expires: 365});
        Cookies.set(constants.COOKIE_USER_ID, response.user_id, {secure: isHttps(), expires: constants.COOKIE_FOREVER_DURATION});
        this.generateDeviceIdIfNotExist();
        window.location.href = '/';
      }
    });
  }

  render() {
    return (
      <div>
        <br/>
        <br/>
        <h1>Gotta log-in or create an account first, chief. Scene.</h1>
        <br/>
        <Alert show={this.state.showAlert} variant='danger'>{this.state.alertMessage}</Alert>
        <Form>
          <Form.Group controlId="formGroupUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" ref={(text) => this.username = text} placeholder="Enter username" />
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={(text) => this.password = text} placeholder="Enter password" />
          </Form.Group>
          <Form.Group>
            <Button as="input" type="submit" value="Login" onClick={this.onLoginButtonClick} />
          </Form.Group>
          <Form.Group>
            <Button as="input" type="submit" value="Register" onClick={this.onRegisterButtonClick} />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default Login;
