import * as constants from './Constants.js';

import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie';
import Col from "react-bootstrap/Col";
import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";

function createObjectURL(object) {
  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

class CreateScene extends React.Component {
  constructor(props) {
    super(props);
    fetch(constants.API_ADDRESS + '/friends', {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
          this.setFriends(response.friends);
      } else if (response.status == 'error' && response.message == 'invalid_auth_token') {
        window.location.href = '/login';
      }
    });

    this.state = {
      imageUrl: '',
      friends: [],
      showAlert: false,
      alertMessage: '',
      alertVariant: 'success',
      isCurrentlySubmitting: false
    };
    this.checked = [];
    this.onImageUrlFieldChange = this.onImageUrlFieldChange.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.fileInputChanged = this.fileInputChanged.bind(this);
  }

  componentDidMount() {
    document.getElementById("fileInput").onchange = this.fileInputChanged;
  }

  getFileInput() {
    var files = document.getElementById("fileInput").files;
    if (files.length == 0) {
      return null;
    }
    var file = files[0];
    if(!file){
      return null;
    }
    return file;
  }

  fileInputChanged() {
    var file = this.getFileInput();
    var src = createObjectURL(file);
    this.imageUrl.value = '';
    this.setState({imageUrl: src});
    console.log(file);
  }

  setFriends(friends) {
    var addedFriends = [];
    var checked = [];
    for (const [index, value] of friends.entries()) {
      if (value.i_added_friend && value.friend_added_me) {
        addedFriends.push(value);
        checked.push(false);
      }
    }
    this.setState({friends: addedFriends, checked: checked});
  }

  onImageUrlFieldChange(event) {
    document.getElementById("fileInput").value = '';
    this.setState({imageUrl: event.target.value});
  }

  onSubmitButtonClick(event) {
    event.preventDefault();
    const recipients = [];
    for (const [index, value] of this.checked.entries()) {
      if (value) {
        recipients.push(this.state.friends[index].friend_user_id);
      }
    }
    if (this.imageUrl.value === '' && this.getFileInput() == null) {
      this.setState({showAlert: true, alertMessage: 'Can\'t upload nothing, chief.', alertVariant: 'danger'});
      return;
    }
    if (recipients.length == 0) {
      this.setState({showAlert: true, alertMessage: 'Gotta select (or make) some friends, chief.', alertVariant: 'danger'});
      return;
    }
    this.setState({isCurrentlySubmitting: true});
    var file = this.getFileInput();
    if (file == null) {
      this.postImage(this.imageUrl.value, recipients);
    } else {
      fetch(constants.API_ADDRESS + '/s3_sign?file_type=' + file.type, {
        method: 'GET',
        crossDomain: true,
        headers: {
          'UserId': Cookies.get(constants.COOKIE_USER_ID),
          'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
          'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
        }
      }).then(response => response.json())
      .then(response => {
        console.log('got signed request', response);
        if (response.status == 'error') {
          this.setState({isCurrentlySubmitting: false, showAlert: true, alertMessage: 'Submission error. Tough scene.', alertVariant: 'danger'});
          return;
        }
        const formData = new FormData()
        for(var key in response.presigned.fields){
          formData.append(key, response.presigned.fields[key]);
        }
        var uploadedUrl = response.url;
        formData.append('file', file)
        fetch(constants.S3_ADDRESS, {
          method: 'POST',
          crossDomain: true,
          body: formData
        }).then(response => {
          this.setState({isCurrentlySubmitting: false});
          console.log('submitted to S3', response, uploadedUrl);
          this.postImage(uploadedUrl, recipients);
        });
      });
    }
  }

  postImage(imageUrl, recipients) {
    fetch(constants.API_ADDRESS + '/image_post', {
      method: 'POST',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      },
      body: JSON.stringify({
        'image_url': imageUrl,
        'recipients': recipients
      })
    }).then(response => response.json())
    .then(response => {
      this.setState({isCurrentlySubmitting: false});
      if (response.status == 'ok') {
        this.setState({showAlert: true, alertMessage: 'Successfully submitted', alertVariant: 'success'});
        window.location.href = '/scene/view/' + response.image_post_id;
      }
      console.log(response);
    });
  }

  render() {
    const recipients = [];

    for (const [index, value] of this.state.friends.entries()) {
      recipients.push(
        <div>
          <Row md={12}>
            <Col md={10}>{value.friend_username}</Col>
            <Col md={2}><Form.Control type="checkbox" checked={this.checked[index]} onChange={(event) => {this.checked[index] = !this.checked[index]; this.setState({})}} /></Col>
          </Row>
          <br/>
        </div>
      );
    }

    return (
      <div>
        <h1>Create a scene, scene.</h1>
        <Image src={this.state.imageUrl} fluid />
        <br/>
        <Form>
          <Form.Group>
            <Form.Label>Image URL</Form.Label>
            <Form.Control type="text" onChange={this.onImageUrlFieldChange} ref={(text) => this.imageUrl = text} placeholder="" />
              <br/>
                or
              <br/><br/>
            <Button variant="light"><input type="file" id="fileInput" accept=".png, .jpg, .jpeg, .gif" /></Button>
          </Form.Group>

          <br/>
          <h3>Send to:</h3>
          {recipients}

          <Alert show={this.state.showAlert} variant={this.state.alertVariant}>{this.state.alertMessage}</Alert>
          <Button type="submit" disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')} onClick={this.onSubmitButtonClick}>Submit</Button>
        </Form>
      </div>
    );
  }
}

export default CreateScene;
