import * as constants from './Constants.js';

import Comment from './Comment.js';
import Cookies from 'js-cookie';
import React from 'react';
import Button from 'react-bootstrap/Button'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";

class AddComment extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitNewCommentButtonClick = this.onSubmitNewCommentButtonClick.bind(this);
  }

  onSubmitNewCommentButtonClick(event) {
    event.preventDefault();
    if (this.newComment.value === '') {
      return;
    }
    this.props.onAddComment(this.newComment.value);
    this.newComment.value = '';
  }

  render() {
    return (
      <Form>
        <Form.Group>
          <Form.Control as="textarea" ref={(text) => this.newComment = text} placeholder="Comment on this scene" rows="3" />
        </Form.Group>
        <Button onClick={this.onSubmitNewCommentButtonClick} disabled={this.props.isCurrentlySubmitting ? ('disabled') : ('')}>Comment</Button>
      </Form>
    );
  }
}

export default AddComment;
