import * as constants from './Constants.js';

import Cookies from 'js-cookie';
import React from 'react';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image';
import BottomScrollListener from 'react-bottom-scroll-listener';

class ImageScene extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <a style={{color:'black', 'text-decoration': 'none'}} href={'/scene/view/' + this.props.imageId}>
        <Row>
          <Col style={{height: '200px'}} xs={12} md={12}>
            <Image style={{'object-fit': 'cover', width: '100%', height: '100%'}} src={this.props.imageUrl} />
          </Col>
        </Row>
        <Row>
          <Col>
            By: {this.props.postedBy}
          </Col>
        </Row>
        </a>
      </div>
    );
  }
}

class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {feed: []};
    this.alreadyLoadedImagePostIds = new Set();
    this.fetchFeed = this.fetchFeed.bind(this);
    this.fetchLatestFeed = this.fetchLatestFeed.bind(this);
    this.fetchPreviousFeed = this.fetchPreviousFeed.bind(this);
  }

  componentDidMount() {
    this.fetchLatestFeed();
    // TODO: Use push-based sockets to reduce server load.
    setInterval(this.fetchLatestFeed, constants.REFRESH_INTERVAL);
  }

  fetchLatestFeed() {
    var latestTimestamp = 0;
    var num = 10;
    if (this.state.feed.length > 0) {
      // If the feed length is >0, then it means we've already fetched the initial
      // set of posts in our feed. Thus, we set "num" to a high number to ensure
      // we get all subsequent posts after the latestTimestamp - i.e. we don't
      // want to limit the number of recent posts that we retrieve.
      //
      // We have this limit before getting the initial list to ensure we don't
      // populate the user's feed with a large number of old posts, since the
      // latestTimestamp will assume we go back indefinitely to retrieve posts.
      latestTimestamp = this.state.feed[0]._id.timestamp;
      num = 100;
    }
    this.fetchFeed('?after_timestamp=' + latestTimestamp + '&num=' + num, (posts) => {
      var feed = this.state.feed;
      feed = posts.concat(feed);
      this.setState({feed: feed});
    });
  }

  fetchPreviousFeed() {
    var previousTimestamp = 0;
    if (this.state.feed.length > 0) {
      previousTimestamp = this.state.feed[this.state.feed.length - 1]._id.timestamp
    }
    this.fetchFeed('?before_timestamp=' + previousTimestamp, (posts) => {
      var feed = this.state.feed;
      feed = feed.concat(posts);
      this.setState({feed: feed});
    });
  }

  fetchFeed(urlArguments, callback) {
    fetch(constants.API_ADDRESS + '/feed' + urlArguments, {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        const newPosts = [];
        for (const [index, value] of response.received_posts.entries()) {
          if (!this.alreadyLoadedImagePostIds.has(value.image_post.id)) {
            this.alreadyLoadedImagePostIds.add(value.image_post.id);
            newPosts.push(value);
          }
        }
        callback(newPosts);
      } else if (response.status == 'error' && response.message == 'invalid_auth_token') {
        window.location.href = '/login';
      }
    });
  }

  render() {
    const imageScenes = [];

    for (const [index, value] of this.state.feed.entries()) {
      var postedBy = value.image_post.posted_by_user_id == Cookies.get(constants.COOKIE_USER_ID) ? 'Me' : value.image_post.posted_by_username;
      imageScenes.push(
        <div>
          <ImageScene imageId={value.image_post.id} imageUrl={value.image_post.image_url} postedBy={postedBy}/>
          <br/>
        </div>
      );
    }

    return (
      <div>
        <h1>Scenes</h1>
        <br/>
        {imageScenes.length == 0 ? (
          <p>Make some friends first, chief. They'll send you stuff that will show here.</p>
        ) : (
          imageScenes
        )}
        <BottomScrollListener onBottom={this.fetchPreviousFeed} offset={100} />
      </div>
    );
  }
}

export default Feed;
