import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class Comment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <h4>{this.props.comment}</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            {this.props.author}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Comment;
