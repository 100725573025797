import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA8rqR-59GvqoPPPI0vT1RSGwbDS-wdZMI",
  authDomain: "viewfinder-f645a.firebaseapp.com",
  databaseURL: "https://viewfinder-f645a.firebaseio.com",
  projectId: "viewfinder-f645a",
  storageBucket: "viewfinder-f645a.appspot.com",
  messagingSenderId: "773376490539",
  appId: "1:773376490539:web:96b1c6534eb01fc5c73e6e"
});
var messaging;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
  	// Project Settings => Cloud Messaging => Web Push certificates
    "BLfGAY6iA3GEL45U4Tt7Jz0V1P3_wdfZXD_RlSEEd21gZii0YXDx4ul6ReNaWzFqGWddG7guT2ybp5uId--wFVs"
  );
} else {
  messaging = null;
}
export { messaging };
