// export const API_ADDRESS = 'http://localhost:1738';
export const API_ADDRESS = 'https://y-backend.com';
export const S3_ADDRESS = 'https://s3-us-west-2.amazonaws.com/scene-uploads.y-backend.com';
export const COOKIE_AUTH_TOKEN = 'cookie_auth_token';
export const COOKIE_USER_ID = 'cookie_user_id';
export const COOKIE_DEVICE_ID = 'cookie_device_id';
export const COOKIE_LAST_NOTIFICATION_TIMESTAMP = 'cookie_last_notification_timestamp'
export const COOKIE_FOREVER_DURATION = 999999;

export const APP_TITLE = 'Scene';
export const REFRESH_INTERVAL = 5000;
