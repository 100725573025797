import * as constants from './Constants.js';

import AddComment from './AddComment.js';
import Comment from './Comment.js';
import Cookies from 'js-cookie';
import React from 'react';
import Button from 'react-bootstrap/Button'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";

class ViewScene extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.match.params.sceneId);
    this.imagePostId = this.props.match.params.sceneId;
    fetch(constants.API_ADDRESS + '/image_post?image_post_id=' + this.imagePostId, {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
          var postedBy = response.image_post.image_post.posted_by_user_id == Cookies.get(constants.COOKIE_USER_ID) ? 'Me' : response.image_post.image_post.posted_by_username;
          var recipients = [];
          for (const [index, value] of response.image_post.image_post.recipients.entries()) {
            recipients.push(value.user_id == Cookies.get(constants.COOKIE_USER_ID) ? 'Me' : value.username);
          }
          recipients = recipients.join(', ');
          this.setState({imageUrl: response.image_post.image_post.image_url, postedBy: postedBy, sharedWith: recipients});
      } else if (response.status == 'error' && response.message == 'invalid_auth_token') {
        window.location.href = '/login';
      }
    });

    this.fetchComments = this.fetchComments.bind(this);
    this.onAddComment = this.onAddComment.bind(this);

    this.state = {imageUrl: '', postedBy: '', sharedWith: '', comments: [], isCurrentlySubmittingComment: false};
  }

  componentDidMount() {
    this.fetchComments();
    // TODO: Use push-based sockets to reduce server load.
    setInterval(this.fetchComments, constants.REFRESH_INTERVAL);
  }

  fetchComments() {
    fetch(constants.API_ADDRESS + '/comments?image_post_id=' + this.imagePostId, {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      if (response.status == 'ok') {
        this.setState({comments: response.comments});
      }
      console.log(response);
    });
  }

  onAddComment(newComment) {
    console.log(newComment);
    this.setState({isCurrentlySubmittingComment: true});
    fetch(constants.API_ADDRESS + '/comments', {
      method: 'POST',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      },
      body: JSON.stringify({
        'image_post_id': this.imagePostId,
        'comment': newComment
      })
    }).then(response => response.json())
    .then(response => {
      this.setState({isCurrentlySubmittingComment: false});
      console.log(response);
    });
    var comments = this.state.comments;
    comments.unshift({_id: {commented_by_user_id: Cookies.get(constants.COOKIE_USER_ID)}, comment: newComment, commented_by_username: 'Me'});
    this.setState({comments: comments});
  }

  render() {
    const comments = [];

    for (const [index, value] of this.state.comments.entries()) {
      var commentedBy = value['_id'].commented_by_user_id == Cookies.get(constants.COOKIE_USER_ID) ? 'Me' : value.commented_by_username;
      comments.push(
        <div>
          <Comment comment={value.comment} author={commentedBy}/>
          <br/>
        </div>
      );
    }

    return (
      <div>
        <br/>
        <h1>Scene</h1>
        <br/>
        <br/>
        <Row>
          <Col xs={12} md={12}>
            <Image src={this.state.imageUrl} fluid />
          </Col>
        </Row>
        <br/>
        <Row>Posted by: {this.state.postedBy}</Row>
        <Row>Shared with: {this.state.sharedWith}</Row>
        <br/>
        <br/>

        <AddComment onAddComment={this.onAddComment} isCurrentlySubmitting={this.state.isCurrentlySubmittingComment} />

        <br/>
        <br/>

        {comments}
      </div>
    );
  }
}

export default ViewScene;
