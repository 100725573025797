import * as constants from './Constants.js';

import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie';
import Col from "react-bootstrap/Col";
import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";

const NOTIFICATION_TYPE_NEW_POST = 'notification_type_new_post'
const NOTIFICATION_TYPE_NEW_COMMENT = 'notification_type_new_comment'
const NOTIFICATION_TYPE_FRIEND_ACCEPTED_REQUEST = 'notification_type_friend_accepted_request'
const NOTIFICATION_TYPE_NEW_FRIEND_REQUEST = 'notification_type_new_friend_request'

function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
         return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay ) {
         return Math.round(elapsed / msPerHour ) + ' hours ago';
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
        return Math.round(elapsed / msPerYear ) + ' years ago';
    }
}

class AppNotification extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var backgroundClass = this.props.isViewed ? 'bg-white' : 'bg-light';
    return (
      <div>
        <Row className={backgroundClass} style={{'padding-top': '15px', 'padding-bottom': '15px'}}>
          <Col md={9}><b><a href={this.props.link}>{this.props.name}</a></b></Col>
          <Col md={3}>{timeDifference(new Date().getTime(), this.props.timestamp)}</Col>
        </Row>
        <br/>
      </div>
    );
  }
}

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.setNotifications = this.setNotifications.bind(this);
    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.state = {notifications: []};
    this.props.setHasNewNotificationsFunction(false);
  }

  componentDidMount() {
    this.fetchNotifications();
    setInterval(this.fetchNotifications, constants.REFRESH_INTERVAL);
  }

  fetchNotifications() {
    fetch(constants.API_ADDRESS + '/notification', {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
          this.setNotifications(response.notifications);
      } else if (response.status == 'error' && response.message == 'invalid_auth_token') {
        window.location.href = '/login';
      }
    });
  }

  setNotifications(rawNotifications) {
    const notifications = [];
    for (const [index, value] of rawNotifications.entries()) {
      var name;
      var link;
      if (value.notification.notification_type === NOTIFICATION_TYPE_NEW_POST) {
        name = 'New post by ' + value.notification.posted_by_username;
        link = '/scene/view/' + value.notification.image_post_id;
      } else if (value.notification.notification_type === NOTIFICATION_TYPE_NEW_COMMENT) {
        name = value.notification.commented_by_username + ' commented on a post ';
        link = '/scene/view/' + value.notification.image_post_id;
      } else if (value.notification.notification_type === NOTIFICATION_TYPE_FRIEND_ACCEPTED_REQUEST) {
        name = value.notification.friend_username + ' accepted your friend request';
        link = '/friends';
      } else if (value.notification.notification_type === NOTIFICATION_TYPE_NEW_FRIEND_REQUEST) {
        name = value.notification.friend_username + ' sent you a friend request';
        link = '/friends';
      }
      notifications.push({
        name: name,
        link: link,
        timestamp: value._id.timestamp,
        isViewed: value.is_viewed
      });
    }
    this.setState({notifications: notifications});
  }

  render() {
    const notifications = [];
    for (const [index, value] of this.state.notifications.entries()) {
      notifications.push(<div><AppNotification name={value.name} link={value.link} timestamp={value.timestamp} isViewed={value.isViewed} /></div>);
    }

    return (
      <div>
        <h1>Notifications</h1>
        <br/>
        {this.state.notifications.length == 0 ? (
          <p>No notifications. Tough scene.</p>
        ) : (
          notifications
        )}
      </div>
    );
  }
}

export default Notifications;
