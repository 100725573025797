import * as constants from './Constants.js';

import Alert from 'react-bootstrap/Alert'
import Col from "react-bootstrap/Col";
import Cookies from 'js-cookie';
import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

class ManageFriendActionSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const friendsToManage = [];

    for (const [index, value] of this.props.friendsToManage.entries()) {
      friendsToManage.push(
        <div>
          <Row md={12}>
            <Col md={9}>{value.friend_username}</Col>
            <Col md={3}><Button type="submit" className="float-right" onClick={(event) => {this.props.onActionClick(index)}}>{this.props.actionText}</Button></Col>
          </Row>
          <br/>
        </div>
      );
    }

    return (
      <div>
        {friendsToManage}
      </div>
    );
  }
}

class ManageFriends extends React.Component {
  constructor(props) {
    super(props);
    this.onAddFriendButtonClick = this.onAddFriendButtonClick.bind(this);
    this.confirmFriendship = this.confirmFriendship.bind(this);
    this.removeFriendship = this.removeFriendship.bind(this);
    this.fetchFriends = this.fetchFriends.bind(this);

    this.state = {
      addedFriends: [],
      sentFriendships: [],
      awaitingFriendships: [],
      showAlert: false,
      alertMessage: ''
    };
  }

  componentDidMount() {
    this.fetchFriends();
    setInterval(this.fetchFriends, 5000);
  }

  fetchFriends() {
    fetch(constants.API_ADDRESS + '/friends', {
      method: 'GET',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      }
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
          this.setFriends(response.friends);
      } else if (response.status == 'error' && response.message == 'invalid_auth_token') {
        window.location.href = '/login';
      }
    });
  }

  setFriends(friends) {
    var addedFriends = [];
    for (const [index, value] of friends.entries()) {
      if (value.i_added_friend && value.friend_added_me) {
        addedFriends.push(value);
      }
    }

    var sentFriendships = [];
    for (const [index, value] of friends.entries()) {
      if (value.i_added_friend && !value.friend_added_me) {
        sentFriendships.push(value);
      }
    }

    var awaitingFriendships = [];
    for (const [index, value] of friends.entries()) {
      if (!value.i_added_friend && value.friend_added_me) {
        awaitingFriendships.push(value);
      }
    }

    this.setState({
      addedFriends: addedFriends,
      sentFriendships: sentFriendships,
      awaitingFriendships: awaitingFriendships
    });
  }

  onAddFriendButtonClick(event) {
    event.preventDefault();
    fetch(constants.API_ADDRESS + '/friends', {
      method: 'POST',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      },
      body: JSON.stringify({
        'friend_username': this.friendUsername.value
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        this.fetchFriends();
        this.setState({showAlert: false, alertMessage: ''});
        this.friendUsername.value = '';
      } else if (response.status == 'error' && response.message == 'username_not_found') {
        this.setState({showAlert: true, alertMessage: 'Username not found.'});
      }
    });
  }

  confirmFriendship(index) {
    fetch(constants.API_ADDRESS + '/friends', {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      },
      body: JSON.stringify({
        'friend_user_id': this.state.awaitingFriendships[index].friend_user_id,
        'user_added_friend': true
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        this.fetchFriends();
        this.setState({showAlert: false, alertMessage: ''});
      }
    });
  }

  removeFriendship(index, listToRemove) {
    fetch(constants.API_ADDRESS + '/friends', {
      method: 'DELETE',
      crossDomain: true,
      headers: {
        'UserId': Cookies.get(constants.COOKIE_USER_ID),
        'DeviceId': Cookies.get(constants.COOKIE_DEVICE_ID),
        'Authorization': Cookies.get(constants.COOKIE_AUTH_TOKEN)
      },
      body: JSON.stringify({
        'friend_user_id': listToRemove[index].friend_user_id,
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.status == 'ok') {
        this.fetchFriends();
        this.setState({showAlert: false, alertMessage: ''});
      }
    });
  }

  render() {
    return (
      <div>
        <Alert show={this.state.showAlert} variant='danger'>{this.state.alertMessage}</Alert>
        <h1>Forever alone scene.</h1>
        <br/>
        <h3>Added You:</h3>
        <br/>
        {/* TODO: add button to ignore confirm friendship. */}
        <ManageFriendActionSection friendsToManage={this.state.awaitingFriendships} actionText='Confirm' onActionClick={this.confirmFriendship} />
        <br/>
        <h3>Pending Acceptance:</h3>
        <br/>
        <ManageFriendActionSection friendsToManage={this.state.sentFriendships} actionText='Cancel' onActionClick={(index) => this.removeFriendship(index, this.state.sentFriendships)} />
        <br/>
        <h3>Current Friends:</h3>
        <br/>
        <ManageFriendActionSection friendsToManage={this.state.addedFriends} actionText='Remove' onActionClick={(index) => this.removeFriendship(index, this.state.addedFriends)} />
        <br/>
        <h3>Add Friend</h3>
        <Form>
          <Form.Group controlId="formUsername">
            <Form.Label>Friend's Username</Form.Label>
            <Form.Control type="text" ref={(text) => this.friendUsername = text} placeholder="" />
          </Form.Group>
          <Button type="submit" onClick={this.onAddFriendButtonClick}>Add</Button>
        </Form>
      </div>
    );
  }
}

export default ManageFriends;
